import Plugin from "../plugin-system/Plugin";

export interface ScrollBreakPoints {
    sm: number,
    md: number,
    xl: number
}

export interface Viewports {
    [index: string]: number;
}

export default class ScrollMenuPlugin extends Plugin {
    protected headSliderImage: HTMLElement | null;
    protected headSliderImageSelector: string;
    protected header: HTMLElement | null;
    protected headerSelector: string;
    protected hasHeadImage: boolean;
    protected scrollBreakPoints: ScrollBreakPoints;
    protected viewports: Viewports;
    protected currentViewport: number;
    protected currentScrollPosition: number;
    protected headerFadeoutClass: string;
    protected siteWrapper: HTMLElement | null;
    protected siteWrapperSelector: string;

    constructor() {
        super('ScrollMenuPlugin');

        this.headSliderImage = null;
        this.header = null;
        this.siteWrapper = null;

        this.headerSelector = '.header';
        this.headSliderImageSelector = '.head-slider .carousel-inner';
        this.headerFadeoutClass = 'site-wrapper--header-fade-out';

        this.siteWrapperSelector = '.site-wrapper';

        this.viewports = {
            sm: 767,
            md: 1319,
            xl: 9999
        };

        this.scrollBreakPoints = {
            sm: 300,
            md: 700,
            xl: 700
        }

        this.currentViewport = this.viewports.sm;
        this.currentScrollPosition = 0;

        this.hasHeadImage = false;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        super.initPlugin(htmlElement);

        this.headSliderImage = this.el.querySelector(this.headSliderImageSelector);

        if (this.headSliderImage !== null) {
            this.hasHeadImage = true;
        }

        this.header = this.el.querySelector(this.headerSelector);
        this.siteWrapper = this.el.querySelector(this.siteWrapperSelector);

        this.currentScrollPosition = this.detectScrollPosition();
        this.currentViewport = this.detectViewport();

        this.registerEvents();

        return true;
    }

    registerEvents(): void {
        window.addEventListener('scroll', this.onScroll.bind(this));
        window.addEventListener('resize', this.onResize.bind(this));
    }

    onResize(): void {
        this.currentViewport = this.detectViewport();

        this.detectHeadImagePosition();
        this.detectHeaderPosition();

        this.setHeaderState();
    }

    onScroll(): void {
        this.detectHeadImagePosition();

        this.setHeaderState();
    }

    setHeaderState(): void {
        let scrollDirection = 'down',
            scrollOffset = this.scrollBreakPoints.sm,
            scrollPosition = this.detectScrollPosition();

        if (this.currentScrollPosition > scrollPosition) {
            scrollDirection  = 'up';
        }

        this.currentScrollPosition = scrollPosition;

        if (this.currentViewport === this.viewports.sm) {
            scrollOffset = this.scrollBreakPoints.sm;
        } else if (this.currentViewport === this.viewports.md) {
            scrollOffset = this.scrollBreakPoints.md;
        } else {
            scrollOffset = this.scrollBreakPoints.xl;
        }

        if (scrollDirection === 'down' && scrollOffset - scrollPosition <= 0) {
            this.siteWrapper!.classList.add(this.headerFadeoutClass);
        }

        if (scrollDirection === 'up') {
            this.siteWrapper!.classList.remove(this.headerFadeoutClass);
        }
    }

    detectHeadImagePosition(): number {
        if (this.headSliderImage !== null) {
            let boundingRect = this.headSliderImage.getBoundingClientRect();
            let endPosition = boundingRect.y + boundingRect.height;

            return endPosition;
        }

        return 0;
    }

    detectHeaderPosition(): number {
        let boundingRect = this.header!.getBoundingClientRect();
        let endPosition = boundingRect.y + boundingRect.height;

        return endPosition;
    }

    detectScrollPosition(): number {
        return window.scrollY;
    }

    detectViewport(): number {
        if (window.innerWidth < this.viewports.sm) {
            return this.viewports.sm
        } else if(window.innerWidth < this.viewports.md) {
            return this.viewports.md;
        } else {
            return this.viewports.xl;
        }
    }
}
