import Plugin from "../plugin-system/Plugin";

export default class Brick09Plugin extends Plugin {
    protected body: HTMLElement | null;
    protected modalFinish: HTMLElement | null;
    protected modalForm: HTMLElement | null;
    protected form: HTMLElement | null;

    constructor() {
        super('Brick09Plugin');

        this.body = null;
        this.form = null;
        this.modalFinish = null;
        this.modalForm = null;
    }

    initPlugin(htmlElement: HTMLElement): boolean {
        let me = this;

        super.initPlugin(htmlElement);

        if (me.el === undefined) {
            return false;
        }

        me.body = document.body;
        me.form = me.el.querySelector('form');
        me.modalFinish = me.el.querySelector('.brick-09__modal-finish');
        me.modalForm = me.el.querySelector('.brick-09__modal-form');

        // move form at end of body due to overlaying effects
        me.body.append(me.form);

        me.el.querySelector('.brick-09__form-button').addEventListener('click', function (event: Event) {
            me.body.classList.add('modal-open');
            me.modalForm?.classList.add('show');

            me.modalForm?.querySelector('textarea#g-recaptcha-response').setAttribute('required', '');

            event.stopPropagation();
        });

        me.modalForm.querySelector('button.close').addEventListener('click', function () {
            me.body.classList.remove('modal-open');
            me.modalForm?.classList.remove('show');
        });

        me.modalFinish.querySelector('button.close').addEventListener('click', function () {
            me.body.classList.remove('modal-open');
            me.modalFinish?.classList.remove('show');
        });

        me.body.addEventListener('click', function (event: Event) {
            if (!event.target.closest('.brick-09__modal .modal-dialog')) {
                me.body.classList.remove('modal-open');
                me.modalForm?.classList.remove('show');
                me.modalFinish?.classList.remove('show');
            }
        });

        me.form.querySelectorAll('input[name="project-diaphragm-form"]').forEach(function (node) {
            node.addEventListener('change', function () {
                let otherRadio = me.form.querySelector('#b09PDiaphragmFormOther'),
                    otherInput = me.form.querySelector('#b09PDiaphragmFormOtherText');

                otherInput.required = otherRadio.checked;
            });
        });

        me.form.querySelectorAll('input[name="project-diaphragm-color"]').forEach(function (node) {
            node.addEventListener('change', function () {
                let otherRadio = me.form.querySelector('#b09PDiaphragmColorOther'),
                    otherInput = me.form.querySelector('#b09PDiaphragmColorOtherText');

                otherInput.required = otherRadio.checked;
            });
        });

        me.form.addEventListener('submit', function (event: Event) {
            event.preventDefault();

            let fields = me.form?.querySelectorAll('input, textarea'),
                formData = new FormData(),
                x = new XMLHttpRequest();

            for (let field of fields) {
                if (field.type == 'checkbox') {
                    formData.append(field.name, field.checked);
                } else if (field.type == 'radio') {
                    if (field.checked) {
                        formData.append(field.name, field.value);
                    }
                } else {
                    formData.append(field.name, field.value);
                }
            }


            x.open('POST', '/contact-form/submit');
            x.onloadend = function () {
                let responseData = {};

                try {
                    responseData = JSON.parse(x.response);
                } catch (e) {
                    responseData = {success: false};
                }

                if (responseData.success) {
                    me.modalFinish?.classList.add('show');
                    me.modalForm?.classList.remove('show');
                    me.form.reset();
                }
            };
            x.send(formData);

            return false;
        });

        return true;
    }
}
